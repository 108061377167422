export var SearchList = [
  { labe: '采购单编号', code: 'purchaseOrderNumber', type: 'input' },
  { labe: '物料编号', code: 'itemNumber', type: 'input' },
  { labe: '物料凭证号', code: 'materialVoucherNo', type: 'input' },
  {
    labe: '过账日期',
    code: 'postingDate',
    type: 'daterange'
  }
]
export var SearchData = {
  purchaseOrderNumber: '',
  itemNumber: '',
  materialVoucherNo: '',
  // postingDate: ''
  strEndDate: '',
  strStartDate: ''
}
export var tableField = [
  { selectType: 'selection', width: '', type: 'input' },
  { label: '采购单编号', code: 'purchaseOrderNumber', type: 'input', width: '120' },
  { label: '采购单行号', code: 'purchaseLineNumber', type: 'input', width: '120' },
  { label: '物料编号', code: 'itemNumber', type: 'input', width: '' },
  { label: '物料描述', code: 'materialDescription', type: 'input', width: '' },
  { label: '收货数量', code: 'quantityReceived', type: 'input', width: '' },
  { label: '过账日期', code: 'postingDate', type: 'input', width: '120' },
  { label: '本次含税金额', code: 'amountIncludingTax', type: 'input', width: '120' },
  { label: '本次未税金额', code: 'amountNotTaxedThisTime', type: 'input', width: '120' },
  { label: '税率', code: 'taxRate', type: 'input', width: '' },
  { label: '本次税额', code: 'currentTaxAmount', type: 'input', width: '' },
  { label: '内部项目号', code: 'internalProjectNumber', type: 'input', width: '120' },
  { label: '内部项目标识', code: 'internalProjectIdentification', type: 'input', width: '120' },
  { label: '物料凭证号', code: 'materialVoucherNo', type: 'input', width: '120' },
  { label: '物料凭证行号', code: 'materialVoucherLineNumber', type: 'input', width: '110' }
]
export var tableData = []
