<template>
  <div class="tableList">
    <list-search :childData="childData" @onSearch="search"  @clearSearch="search"></list-search>
    <global-table @SelectAction="detailSelect" ref="addDetailtable" max-height="250px"  :tableField="tableField"  :tableData="tableData"></global-table>
  </div>
</template>

<script>
import ListSearch from '@/components/actionList/ListSearch.vue'
import GlobalTable from '@/components/actionList/GlobalTable.vue'
import { requestForm } from '@/assets/js/http.js'
import dayjs from 'dayjs'
import { SearchList, SearchData, tableField, tableData } from './js/statementDetaildialog'
export default {
  name: 'StatementDetaildialog',
  props: ['addData'],
  components: { ListSearch, GlobalTable },
  data () {
    return {
      tableField: tableField,
      tableData: tableData,
      searchdata: SearchData,
      pageNum: 1,
      pageSize: 99999,
      id: '',
      childData: { searchList: SearchList, searchData: SearchData },
      corporateName: ''
    }
  },
  created () {
    this.StatementDetaildialogList()
  },
  methods: {
    detailSelect (allData, data) {
      this.$nextTick(() => {
        // eslint-disable-next-line eqeqeq
        if (this.addData.consignmentOrNot == 0) {
          var table = this.$refs.addDetailtable.$refs.table
          const metCode = data.itemNumber
          for (const item of this.tableData) {
            if (item.itemNumber === metCode && data !== item) {
              table.toggleRowSelection(item)
            }
          }
        }
      })
    },
    StatementDetaildialogList () {
      const obj = {
        pageNum: this.pageNum,
        pageSize: this.pageSize,
        corporateName: this.addData.corporateName, // 公司名称
        supplier: this.addData.supplier, // 供应商
        statementType: this.addData.statementType, // 对账单类型
        billingParty: this.addData.billingParty, // 开票方
        currency: this.addData.currency, // 币种
        consignmentOrNot: this.addData.consignmentOrNot, // 是否寄售:0是,1不是
        remarks: this.addData.remarks
      }
      this.searchData = obj
      requestForm('/api/reconciliation/reconciliation/queryByAccountStatement', 'post', obj).then((res) => {
        if (res.code === '200') {
          this.tableData = res.data.records
        }
      })
    },
    dateFormate: function (data) {
      return dayjs(data).format('YYYY-MM-DD')
    },
    search: function (data) {
      const obj = {
        pageNum: this.pageNum,
        pageSize: this.pageSize,
        corporateName: this.addData.corporateName, // 公司名称
        supplier: this.addData.supplier, // 供应商
        statementType: this.addData.statementType, // 对账单类型
        billingParty: this.addData.billingParty, // 开票方
        currency: this.addData.currency, // 币种
        consignmentOrNot: this.addData.consignmentOrNot, // 是否寄售:0是,1不是
        remarks: this.addData.remarks
      }
      this.searchdata = { ...data, ...obj }
      delete this.searchdata.postingDate
      if (data.postingDate) {
        this.searchdata.strEndDate = this.dateFormate(data.postingDate[1])
        this.searchdata.strStartDate = this.dateFormate(data.postingDate[0])
      }
      requestForm('/api/reconciliation/reconciliation/queryByAccountStatement', 'post', this.searchdata).then((Response) => {
        if (Response.code === '200') {
          this.tableData = Response.data.records
        }
      })
    }
  }
}
</script>
<style scoped lang="scss">
 .tableList{
// padding: 0px;
.tableNav {
    border-bottom:none;

}
}

</style>
